import axios from 'axios';
import { useEffect, useState } from 'react';
import { MIKOM_API_IMAGE } from '../utils/constants';

const useGetImages = (picture, firmaId, categoryId) => {
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${MIKOM_API_IMAGE}${picture}/${firmaId}/${categoryId}`
        );
        setImage(response?.data);
      } catch (err) {
        console.log(err.response);
        setError(err.response);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [categoryId]);

  return { image, isLoading, error };
};

export default useGetImages;
