import { ShoppingBagIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import useGetImages from '../hooks/useGetImages';
import { useDispatch } from 'react-redux';
import { cartActions } from '../features/cart/cartSlice';
import toast from 'react-hot-toast';
import ShimmerCard from './ShimmerCard';

const ProductModal = ({ setCartModal, id, productDetail }) => {
  // console.log('productDetail', productDetail)
  const [toplam, setToplam] = useState(1);
  const dispatch = useDispatch();
  const { image: uri, isLoading } = useGetImages(
    'product_pictures',
    id,
    productDetail?.URUN_KOD
  );
  const modalRef = useRef();
  const [note, setNote] = useState('');

  // opsiyonların tamamı
  const [opsiyon, setOpsiyon] = useState(null);

  // ilave ürün seçenekleri
  const [ilavevalue, setIlaveValue] = useState([]);

  // çıkartılacak ürünler
  const [that, setThat] = useState([]);

  // porsiyon seçenekleri
  const [portion, setsubmitPortion] = useState(null);
  const [portionKatsayi, setPortionKatsayi] = useState(null);
  const [portionValue, setPortionValue] = useState(null);

  //ürünün kendi notları
  const [productNotes, setProductNotes] = useState([]);

  const handleModalClose = e => {
    if (!modalRef.current.contains(e.target)) setCartModal(false);
  };
  const increment = () => {
    setToplam(toplam + 1);
  };

  const decrement = () => {
    if (toplam >= 2) {
      setToplam(toplam - 1);
    }
  };

  let ilavetoplam = 0;
  
  if (ilavevalue[0] !== undefined) {
    ilavevalue.map(
      element => (ilavetoplam += Number(element.value.replace(',', '.')))
    );
  }

  const handleAddToCart = item => {
    dispatch(
      cartActions.addItem({
        id: item?.GENID,
        product_kod: item?.URUN_KOD,
        title: item?.URUN_ADI,
        desc: item?.URUN_ACIKLAMA,
        image: uri,
        price: portionValue
          ? Number(portionValue.replace(',', '.')) + Number(ilavetoplam)
          : Number(item?.FIYATI.replace(',', '.')) + Number(ilavetoplam),
        quantity: toplam,
        totalPrice: portionValue
          ? portionValue.replace(',', '.') * toplam + ilavetoplam * toplam
          : item?.FIYATI.replace(',', '.') * toplam + ilavetoplam * toplam,
        productNote: note,
        category: item?.CATEGORY,
        portion: portion,
        ilave: ilavevalue,
        cikartilacakurun: that,
        urununnotu: productNotes,
        porsiyonkatsayi: portionKatsayi ? portionKatsayi : '1',
        fiyat: portionValue ? portionValue : item?.FIYATI,
        birim: item?.BIRIM
      })
    );
    handleModalClose(false);
    setNote('');
    setToplam(1);
    setsubmitPortion(null);
    setPortionKatsayi(null);
    setPortionValue(null);
    toast.success('Sepete Eklendi!');
  };

  return (
    <div
      onClick={handleModalClose}
      className='z-10 bg-gray-900/70 fixed top-0 left-0 w-full h-full flex justify-center items-center'
    >
      {isLoading ? (
           <div
           ref={modalRef}
           className='w-[90%] flex flex-col md:flex-row max-w-[600px] m-auto p-8 bg-white rounded-md min-h-[240px]'
         >
           <ShimmerCard />
         </div>
     
      ) : (
         <div
          ref={modalRef}
          className='w-[90%] flex flex-col md:flex-col justify-between items-center max-w-[450px] m-auto p-8 bg-white rounded-md min-h-[200px]'
        >
          <img
            src={uri}
            alt='product image'
            className='object-cover w-96 rounded-lg'
          />
          <div className='w-full md:ml-5 flex items-center md:items-start flex-col'>
            <h2 className='text-lg font-semibold text-zinc-800'>
              {productDetail?.URUN_ADI}
            </h2>
            <p className='text-zinc-600'>
              <span>{productDetail?.URUN_ACIKLAMA}</span>
            </p>
            <textarea
              id='myTextarea'
              name='myTextarea'
              rows='4'
              cols='50'
              onChange={e => setNote(e.target.value)}
              value={note}
              placeholder='Ürün Notunu Buraya Yazabilirsiniz...'
              className='p-2 px-1 rounded-md border outline-none focus-within:border-emerald-900 border-gray-200 grow w-full mt-2 mb-2 h-20'
            />
            <div className='flex justify-between items-center mt-2 w-full'>
              <div className='flex items-center'>
                <button
                  onClick={decrement}
                  disabled={toplam === 1}
                  className={
                    'bg-emerald-700 disabled:bg-emerald-700/50 disabled:cursor-not-allowed text-white font-bold w-8 h-8 rounded-md'
                  }
                >
                  -
                </button>
                <p className='font-bold w-8 h-8 flex justify-center items-center'>
                  {toplam}
                </p>
                <button
                  onClick={increment}
                  className='bg-emerald-700 text-white font-bold w-8 h-8 rounded-md'
                >
                  +
                </button>
              </div>
              <h2 className='text-lg font-semibold text-zinc-800'>
                {parseFloat(productDetail?.FIYATI) * toplam} ₺
              </h2>
            </div>
            <button
              disabled={productDetail?.STOKTAYOK === '1' ? true : false}
              onClick={() => handleAddToCart(productDetail)}
              className='w-full mt-3 bg-emerald-900 basis-2/12 text-center text-white p-2 flex justify-center gap-2 items-center md:px-8 rounded-md text-sm md:text-base'
            >
              <ShoppingBagIcon className='w-4 h-4' />
              <span className='md:block'>Sepete Ekle</span>
            </button>
          </div>
        </div>


     
      )}
    </div>
  );
};

export default ProductModal;
