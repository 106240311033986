import useGetImages from "../hooks/useGetImages";

const VerticalItem = ({ item, filterProducts, id, chooseCategory }) => {
  const { image: uri } = useGetImages("category_pictures", id, item?.CATEGORY);
  // console.log('item', item);

  return (
    <div
      className="w-full relative mb-5"
      onClick={() => filterProducts(item?.CATEGORY)}
    >
      <img
        src={uri}
        className="w-full h-60 rounded-md object-fit"
        alt={item?.CATEGORY}
      />
      <div className="absolute bottom-0 left-0 w-full bg-white bg-opacity-25 py-1">
        <p
          className={
            chooseCategory === item?.CATEGORY
              ? "font-bold text-xl text-center tracking-wide uppercase text-emerald-900"
              : "font-bold text-xl text-center tracking-wide uppercase text-black-500"
          }
        >
          {item?.CATEGORY}
        </p>
      </div>
    </div>
  );
};
export default VerticalItem;
