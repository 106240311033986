import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIKOM_API } from '../utils/constants';

export const getRestaurant = createAsyncThunk(
  'restaurant/getRestaurant',
  async data => {
    const response = await fetch(MIKOM_API, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json'
      }
    });
    const restaurantResponse = await response.json();
    return restaurantResponse;
  }
);

export const restaurantSlice = createSlice({
  name: 'restaurantInfo',
  initialState: {
    restaurant: [],
    isLoading: false
  },
  extraReducers: {
    [getRestaurant.pending]: state => {
      state.isLoading = true;
    },
    [getRestaurant.fulfilled]: (state, action) => {
      state.restaurant = action.payload;
      state.isLoading = false;
    },
    [getRestaurant.rejected]: state => {
      state.isLoading = false;
    }
  }
});

export default restaurantSlice;
