import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  totalQuantity: localStorage.getItem("totalQuantity")
    ? JSON.parse(localStorage.getItem("totalQuantity"))
    : 0,
  totalAmount: localStorage.getItem("totalAmount")
    ? JSON.parse(localStorage.getItem("totalAmount"))
    : 0,


};

function arraysEqual(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i].label !== array2[i].label) {
      return false;
    }
  }
  return true;
}

function arraysNotEqual(array1, array2) {
  return !arraysEqual(array1, array2);
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // =========== add item ============
    addItem(state, action) {
      const newProduct = action.payload;
      const controlProduct = state.cartItems.find(
        (item) =>
          item.id === newProduct.id &&
          item.portion === newProduct.portion &&
          item.productNote === newProduct.productNote &&
          (item.ilave[0] === undefined && newProduct.ilave[0] === undefined
            ? true
            : arraysEqual(item.ilave, newProduct.ilave)) &&
          (item.urununnotu[0] === undefined &&
          newProduct.urununnotu[0] === undefined
            ? true
            : arraysEqual(item.urununnotu, newProduct.urununnotu)) &&
          (item.cikartilacakurun[0] === undefined &&
          newProduct.cikartilacakurun[0] === undefined
            ? true
            : arraysEqual(item.cikartilacakurun, newProduct.cikartilacakurun))
      );

      state.totalQuantity += newProduct.quantity;

      if (!controlProduct) {
        state.cartItems.push({
          id: newProduct.id,
          product_kod: newProduct.product_kod,
          title: newProduct.title,
          desc: newProduct.desc,
          image: newProduct.image,
          price: newProduct.price,
          quantity: newProduct.quantity,
          totalPrice: newProduct.totalPrice,
          productNote: newProduct.productNote,
          category: newProduct.category,
          portion: newProduct.portion,
          ilave: newProduct.ilave,
          cikartilacakurun: newProduct.cikartilacakurun,
          urununnotu: newProduct.urununnotu,
          porsiyonkatsayi: newProduct.porsiyonkatsayi,
          fiyat: newProduct.fiyat,
          birim: newProduct.birim,
        });
      } else {
        controlProduct.quantity += newProduct.quantity;

        controlProduct.totalPrice =
          controlProduct.totalPrice + newProduct.price * newProduct.quantity;
      }
      
      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * Number(item.quantity),
        0
      );
      localStorage.setItem("totalQuantity",JSON.stringify(state.totalQuantity));
      localStorage.setItem("totalAmount", JSON.stringify(state.totalAmount));
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    // ========= remove item ========
    removeItem(state, action) {
      const { id, portion, productNote, ilave, cikartilacakurun, urununnotu } =
        action.payload;
      const controlProduct = state.cartItems.find(
        (item) =>
          item.id === id &&
          item.portion === portion &&
          item.productNote === productNote &&
          (item.ilave[0] === undefined && ilave[0] === undefined
            ? true
            : arraysEqual(item.ilave, ilave)) &&
          (item.urununnotu[0] === undefined && urununnotu[0] === undefined
            ? true
            : arraysEqual(item.urununnotu, urununnotu)) &&
          (item.cikartilacakurun[0] === undefined &&
          cikartilacakurun[0] === undefined
            ? true
            : arraysEqual(item.cikartilacakurun, cikartilacakurun))
      );
      state.totalQuantity--;

      if (controlProduct.quantity === 1) {
        state.cartItems = state.cartItems.filter(
          (item) =>
            item.id !== id ||
            item.portion !== portion ||
            item.productNote !== productNote ||
            arraysNotEqual(item.ilave, ilave) ||
            arraysNotEqual(item.urununnotu, urununnotu) ||
            arraysNotEqual(item.cikartilacakurun, cikartilacakurun)
        );
      } else {
        controlProduct.quantity--;
        controlProduct.totalPrice =
          Number(controlProduct.totalPrice) - Number(controlProduct.price);
      }

      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * Number(item.quantity),
        0
      );

      localStorage.setItem("totalQuantity",JSON.stringify(state.totalQuantity));
      localStorage.setItem("totalAmount", JSON.stringify(state.totalAmount));
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    //============ delete item ===========
    deleteItem(state, action) {
      const { id, portion, productNote, ilave, cikartilacakurun, urununnotu } =
        action.payload;

      const controlProduct = state.cartItems.find(
        (item) =>
          item.id === id &&
          item.portion === portion &&
          item.productNote === productNote &&
          (item.ilave[0] === undefined && ilave[0] === undefined
            ? true
            : arraysEqual(item.ilave, ilave)) &&
          (item.urununnotu[0] === undefined && urununnotu[0] === undefined
            ? true
            : arraysEqual(item.urununnotu, urununnotu)) &&
          (item.cikartilacakurun[0] === undefined &&
          cikartilacakurun[0] === undefined
            ? true
            : arraysEqual(item.cikartilacakurun, cikartilacakurun))
      );

      if (controlProduct) {
        state.cartItems = state.cartItems.filter(
          (item) =>
            item.id !== id ||
            item.portion !== portion ||
            item.productNote !== productNote ||
            arraysNotEqual(item.ilave, ilave) ||
            arraysNotEqual(item.urununnotu, urununnotu) ||
            arraysNotEqual(item.cikartilacakurun, cikartilacakurun)
        );
        state.totalQuantity = state.totalQuantity - controlProduct.quantity;
      }
      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * Number(item.quantity),
        0
      );
      
      localStorage.setItem( "totalQuantity",JSON.stringify(state.totalQuantity));
      localStorage.setItem("totalAmount", JSON.stringify(state.totalAmount));
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    deleteCart(state) {
      localStorage.removeItem("cartItems");
      localStorage.removeItem("totalQuantity");
      localStorage.removeItem("totalAmount");
      state.cartItems = [];
      state.totalAmount = 0;
      state.totalQuantity = 0;
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;