import { Outlet, useLocation } from "react-router-dom";
import useOnlineStatus from "./hooks/useOnlineStatus";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeMenu } from "./features/app/appSlice";
import React, { useEffect } from "react";

const App = () => {
  const { pathname } = useLocation();
  const isOnline = useOnlineStatus();
  const { isMenuOpen } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    isMenuOpen && dispatch(closeMenu());
  }, [pathname]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   const requestFullScreen = () => {
  //     const element = document.documentElement;
  //     if (element.requestFullscreen) {
  //       element.requestFullscreen().catch(err => {
  //         console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
  //       });
     
  //     } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
  //       element.webkitRequestFullscreen().catch(err => {
  //         console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
  //       });
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener('contextmenu', handleContextMenu);


  //   requestFullScreen();

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);

  return (
    <>
      {isOnline ? (
        <Outlet />
      ) : (
        <div className="flex flex-col justify-center items-center min-h-screen">
          <h1 className="text-4xl font-bold">Oops! Connection lost</h1>
          <p>
            Looks like you're offline, please check your internet connection.
          </p>
        </div>
      )}
    </>
  );
};

export default App;
