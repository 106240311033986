import { ShoppingBagIcon } from "@heroicons/react/24/solid";
import useGetImages from "../hooks/useGetImages";
import React from "react";

const ProductCard = ({ item, id, setCartModal, setProductDetail }) => {
  
  const { image: uri } = useGetImages("product_pictures", id, item?.URUN_KOD);

  const handleOpenModal = () => {
    setCartModal(true);
    setProductDetail(item);
  };

  if (!item || !uri) return null;
  
  return (
    <div className="mb-4 h-[330px]">
      <div className="overlay-container">
        <img
          src={uri}
          alt="restaurant"
          className="relative w-full h-[180px] overflow-hidden aspect-video object-cover block rounded-md"
        />

        <div className="overlay w-full rounded-md p-2 px-3 ">
          <p className="text-xl font-bold flex gap-2 flex-wrap">
            {item?.FIYATI} ₺
          </p>
        </div>
      </div>

      <div className="h-[150px] mt-2">
        <div className="h-[40px] mb-1">
          <h2 className="text-[16px] font-semibold text-zinc-800">
            {item?.URUN_ADI}
          </h2>
        </div>

        <div className="h-[30px]">
          <p className="truncate text-[10px] font-bold  text-zinc-600">
            <span>{item?.URUN_ACIKLAMA}</span>
          </p>
        </div>

        <div className="h-[50px]">
          <button
            onClick={handleOpenModal}
            className="w-full bg-emerald-900 text-center text-white p-2 flex justify-center gap-2 items-center md:px-8 rounded-md text-sm md:text-base"
          >
            <ShoppingBagIcon className="w-4 h-4" />
            <span className="hidden md:block">Sepete Ekle</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

//  HOC for Top Rated Restaurants
export const withTopRatedLabel = (ProductCard) => {
  return (props) => {
    return (
      <div className="relative">
        <p className="absolute z-10 -top-2 -left-2 rounded-md p-2 px-4 bg-zinc-900 text-white text-xs">
          Yeni
        </p>
        <ProductCard {...props} />
      </div>
    );
  };
};
