import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Logo = () => {
  const restaurant = useSelector(state => state.restaurant.restaurant);

  return (
    <Link
      to='/'
      data-testid='logo'
      className='text-xl md:text-2xl font-semibold flex items-center justify-center'
    >
      <p className='block logo text-[35px]'>{restaurant?.[0]?.FIRMA}</p>
    </Link>
   
  );
};

export default Logo;
