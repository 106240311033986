import { Link } from "react-router-dom";
import ProductCard, { withTopRatedLabel } from "./ProductCard";
import ShimmerCard from "./ShimmerCard";
import React, { useState } from "react";
import ProductModal from "./ProductModal";
import GotoBasket from "./GotoBasket";
import '../css/vertical.css'

const ProductList = ({ isLoading, products, id }) => {
  const ProductCardTopNew = withTopRatedLabel(ProductCard);
  const [cartModal, setCartModal] = useState(false);
  const [productDetail, setProductDetail] = useState();
  // console.log('products', products)

  return (
    <div className="container-max mb-20 h-screen max-h-screen overflow-y-scroll scrollbar-hide">
      <h1 className="my-4 mt-8 font-bold text-2xl text-zinc-700 text-center">
        {products?.[0]?.CATEGORY}
      </h1>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 md:gap-8 ">
        {isLoading ? (
          Array.from({ length: 15 }).map((_, i) => <ShimmerCard key={i} />)
        ) : products && products?.length !== 0 ? (
          products?.map((item, i) => (
            <Link
              className="hover:scale-95 transition ease-in-out duration-300 relative z-10"
              key={i}
            >
              {item?.YENI_URUN === "1" ? (
                <ProductCardTopNew item={item} />
              ) : (
                <ProductCard
                  id={id}
                  item={item}
                  setCartModal={setCartModal}
                  setProductDetail={setProductDetail}
                />
              )}
            </Link>
          ))
        ) : (
          <p className="notfound-product">Ürün Bulunamadı!</p>
        )}
        <GotoBasket  />
      </div>
      
      {cartModal && (
        <ProductModal
          setCartModal={setCartModal}
          productDetail={productDetail}
          id={id}
        />
      )}

    </div>
  );
};

export default ProductList;
