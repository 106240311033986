import Logo from './Logo';

const Header = () => {

  return (
    <header className='sticky w-full top-0 bg-white z-20 py-4 border-b shadow-sm border-gray-100'>
      <Logo />
    </header>
  );
};
export default Header;
