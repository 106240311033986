import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Cart from "./pages/Cart";
import Error from "./pages/Error";
import Home from "./pages/Home";
import store from "./store/store";
import "./styles.css";

const queryParams = new URLSearchParams(window.location.search);
 const id = queryParams.get("id");
//const id = "MP191";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home id={id} />,
      },
      {
        path: "home",
        element: <Home id={id} />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
    ],
    errorElement: <Error />,
  },
]);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
