import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const GotoBasket = () => {
  const navigate = useNavigate();
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        {totalQuantity > 0 && (
          <div className="fixed z-50 bottom-0 left-[43%] flex flex-row justify-between items-center pt-3 bg-black w-2/5 mx-auto rounded-xl text-white p-4 mb-4 mt-4">
            <div>
              <p className="fs-3 fw-2 ml-4 text-xl">Toplam ₺{totalAmount}</p>
            </div>

            <button
              onClick={() => navigate("/cart")}
              className="fs-2 bg-white text-black w-4/6 rounded-full p-2 font-semibold"
            >
              Sepete Git ({totalQuantity})
            </button>
          </div>

        
        )}
      </div>
    </>
  );
};

export default GotoBasket;
