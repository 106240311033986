import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../features/cart/cartSlice";
import { TiTimes } from "react-icons/ti";

const CartItemList = () => {
  const cartProducts = useSelector((state) => state.cart.cartItems);

  // console.log('cartProducts',cartProducts);

  const dispatch = useDispatch();

  const incrementItem = (item) => {
    dispatch(
      cartActions.addItem({
        id: item.id,
        product_kod: item.product_kod,
        title: item.title,
        desc: item.desc,
        image: item.image,
        price: item.price,
        quantity: 1,
        totalPrice: item.totalPrice,
        category: item.category,
        portion: item.portion,
        productNote: item.productNote,
        ilave: item.ilave,
        cikartilacakurun: item.cikartilacakurun,
        urununnotu: item.urununnotu,
        porsiyonkatsayi: item.porsiyonkatsayi,
        fiyat: item.fiyat,
        birim: item.birim,
      })
    );
  };

  const decreaseItem = (item) => {
    dispatch(
      cartActions.removeItem({
        id: item.id,
        portion: item.portion,
        productNote: item.productNote,
        ilave: item.ilave,
        cikartilacakurun: item.cikartilacakurun,
        urununnotu: item.urununnotu,
      })
    );
  };

  const deleteItem = (item) => {
    dispatch(
      cartActions.deleteItem({
        id: item?.id,
        portion: item?.portion,
        productNote: item?.productNote,
        ilave: item?.ilave,
        cikartilacakurun: item?.cikartilacakurun,
        urununnotu: item?.urununnotu,
      })
    );
  };

  if (cartProducts?.length === 0) {
    return (
      <div className="flex grow min-h-[60vh] justify-center items-center text-lg">
        <p>Sepetinizde Ürün Bulunmamaktadır.!</p>
      </div>
    );
  }

  return (
    <ul className="basis-7/12">
      {!!cartProducts &&
        cartProducts?.map((item, index) => (
          <li
            key={index}
            className="flex gap-4 w-full justify-between max-w-[100%] my-4 p-2"
          >
            <div className="basis-3/12">
              <img
                className="w-full h-full md:h-auto object-cover block rounded-md aspect-square"
                src={item?.image}
                alt={item?.title}
              />
            </div>
            <div className="basis-9/12">
              <p className="text-lg font-semibold">{item?.title}</p>
              <p>{item?.desc}</p>

              {!!item.productNote && (
                <p className="my-2 space-x-1">
                  <span className="font-semibold">Ürün Notu:</span>
                  <span className="text-gray-800 font-normal">
                    {item?.productNote}
                  </span>
                </p>
              )}
              <p className="my-2 space-x-1">
                <span className="font-semibold">{item?.totalPrice} ₺</span>
                <span className="text-gray-800 font-normal">
                  ({item?.price} × {item?.quantity})
                </span>
              </p>

              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center">
                  <button
                    onClick={() => decreaseItem(item)}
                    disabled={item?.quantity === 1}
                    className={
                      "bg-emerald-800 disabled:bg-emerald-800/50 disabled:cursor-not-allowed text-white font-bold w-8 h-8 rounded-md"
                    }
                  >
                    -
                  </button>
                  <p className="font-bold w-8 h-8 flex justify-center items-center">
                    {item?.quantity}
                  </p>
                  <button
                    onClick={() => incrementItem(item)}
                    className="bg-emerald-800 text-white font-bold w-8 h-8 rounded-md"
                  >
                    +
                  </button>
                </div>

                <button
                  onClick={() => deleteItem(item)}
                  className="border bg-emerald-800 text-xs font-semibold text-white p-2 rounded-md"
                >
                  <TiTimes className="text-white" size={20} />
                </button>
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default CartItemList;
