import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState, useRef } from "react";
import ProductList from "./ProductList";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../state/categorySlice";
import { getProduct } from "../state/productSlice";
import { getRestaurant } from "../state/restaurantSlice";
import VerticalCategory from "../components/VerticalCategory";
import Header from "./Header";

const Body = ({ id }) => {
  const dispatch = useDispatch();
  const { product, isLoading: productLoading } = useSelector((state) => state.product);
  const { category, isLoading } = useSelector((state) => state.category);
  const serachRef = useRef();
  const [productItems, setProductItems] = useState(product);
  const [list, setList] = useState(null);
  const [chooseCategory, setChooseCategory] = useState();

  useEffect(() => {
    const data1 = { path: id + "/productsno.json" };
    const data2 = { path: id + "/categoryno.json" };
    const data = { path: id + "/restaurant.json" };

    dispatch(getProduct(data1));
    dispatch(getCategory(data2));
    dispatch(getRestaurant(data));
  }, [dispatch, id]);

  useEffect(() => {
    const firstCategories = category?.find((category) => category.CATEGORY);
    setChooseCategory(firstCategories?.CATEGORY);
    if (firstCategories !== undefined) {
      return setProductItems(
        product?.filter((item) => item?.CATEGORY === firstCategories?.CATEGORY)
      );
    }
  }, [category, product]);

  const filterProducts = (category) => {
    setChooseCategory(category);
    const urunler = product?.filter((item) => {
      return item?.CATEGORY === category;
    });
    setProductItems(urunler);
    setList(null);
    serachRef.current.value = "";
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const filteredList = product?.filter((x) => {
      const searcedText = serachRef.current.value;
      const productTitle = x.URUN_ADI?.toUpperCase();
      return productTitle.includes(searcedText?.toUpperCase());
    });

    if (serachRef.current.value !== "") {
      setList(filteredList);
    } else {
      setList(null);
    }
  };

  return (
    <div className="bg-white relative">
      <Header />

      <form
        onSubmit={handleSearch}
        className="flex gap-2 md:gap-4 max-w-[73%] w-[100%] mx-auto mt-4"
      >
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Ürün Ara..."
          className="p-2 px-4 rounded-md border outline-none focus-within:border-emerald-900 border-gray-200 grow w-full"
          ref={serachRef}
        />
        <button
          type="submit"
          className="bg-emerald-900 basis-2/12 text-center text-white p-2 flex justify-center gap-2 items-center md:px-8 rounded-md text-sm md:text-base"
        >
          <MagnifyingGlassIcon className="w-4 h-4" />
          <span className="hidden md:block">Ara</span>
        </button>
      </form>

      <div className="container-max flex gap-8">
        <VerticalCategory
          category={category}
          isLoading={isLoading}
          filterProducts={filterProducts}
          id={id}
          chooseCategory={chooseCategory}
        />
        <ProductList
          isLoading={productLoading}
          products={!!list?.length ? list : productItems}
          id={id}
        />
      </div>
    </div>
  );
};
export default Body;
