import VerticalItem from './VerticalItem';
import '../css/vertical.css';

const VerticalCategory = ({
  category,
  filterProducts,
  id,
  chooseCategory
}) => {
  if (!category) return null;

  return (
    <div className='flex flex-col mt-8 mr-4 w-80 h-screen max-h-screen overflow-y-scroll scrollbar-hide bg-slate-100 p-5 rounded-md'>
      {category?.map((item, index) => (
        <VerticalItem
          item={item}
          key={index}
          filterProducts={filterProducts}
          id={id}
          chooseCategory={chooseCategory}
        />
      ))}
    </div>
  );
};
export default VerticalCategory;
