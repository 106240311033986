import { useRef } from "react";
import resim1 from "../assets/images/7.png";
import Lottie from "react-lottie";
import * as loading from "../assets/lottie/loading3.json";

const BasketModal = ({ setCartModal, isLoading }) => {
  const modalRef = useRef();

  const handleModalClose = (e) => {
    if (!modalRef.current.contains(e.target)) setCartModal(true);
  };

  return (
    <div
      onClick={handleModalClose}
      className="z-10 bg-gray-900/70 fixed top-0 left-0 w-full h-full flex justify-center items-center"
    >
      <div ref={modalRef} className="w-[90%] flex flex-col md:flex-col justify-between items-center max-w-[450px] m-auto p-6 bg-white rounded-md min-h-[600px]">
        <h2 className="text-[30px] font-semibold text-zinc-400">ÖDEMEYİ TAMAMLA</h2>
        <img src={resim1} alt="img" className="modalImg" />
        <p className="text-zinc-600 text-[20px]">
          Lütfen kredi kartınızı cihaza yaklaştırın veya takın.
        </p>
        {isLoading && (
          <Lottie options={defaultOptions} height={100} width={150} />
        )}
      </div>
    </div>

   
  );
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default BasketModal;
